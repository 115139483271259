@import '../../../../styles/basics';

.hub-result-card {
  :global {
    .hub-result-card-container {
      position: relative;
      display: block;
      padding: 24px 10px;
      overflow: hidden;
      transition: transform 220ms cubic-bezier(.165,.84,.44,1);
      border-bottom: 2px solid $slate-200;
      background-color: $white;

      &.featured {
        background-color: $turquoise-100;
        border: none;
      }

      .result-type {
        text-transform: uppercase;
        color: $black;
        font-weight: $font-weight-bold;
        font-size: 14px;
        padding-left: 35px;
        position: relative;
        margin-bottom: 12px;
      }

      .result-icon {
        position: absolute;
        left: -3px;
        top: -2px;
        width: 25px;
        height: 25px;
        padding: 3px;
      }

      h3 {
        display: inline-block;
        color: $turquoise-1200;
        font-weight: $font-weight-bold;
        font-size: 16px;
        line-height: $line-height-large;
        margin-bottom: 4px;
        text-underline-offset: 1px;
        svg {
          margin-left: 10px;
          width: 15px !important;
          height: 15px !important;
        }
      }

      .result-byline {
        font-size: 16px;
        line-height: $line-height-large;
        color: $black;
      }

      .result-description {
        display: none;
        font-size: 16px;
        line-height: $line-height-large;
        color: $slate-1200;
        overflow: hidden;
        text-overflow: ellipsis;

        p {
          color: $slate-1200;
        }
      }

      .result-image-wrapper {
        display: none;
        position: absolute;
        right: 16px;
        top: 75px;
        border-radius: 4px;
        overflow: hidden;
      }

      .result-image {
        max-width: 270px;
        width: 270px;
        height: auto;
      }

      .result-more {
        display: inline-block;
        font-weight: $font-weight-bold;
        font-size: 16px;
        border-bottom: 2px solid $turquoise-1200;
        color: $turquoise-1200;
        margin-top: 14px;
        padding-right: 28px;
        position: relative;
        .arrow {
          position: absolute;
          right: 0;
          top: -2px;
          svg path {
            fill: $turquoise-1200 !important;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        padding: 36px 296px 36px 16px;
        min-height: 256px;
        &:hover, &:focus{
          h3 {
            text-decoration: underline 2px solid $turquoise-1200;
          }
          text-decoration: none;
        }
        &:focus {
          z-index: 1;
        }
        h3 {
          font-size: 20px;
          margin-bottom: 10px;
        }
        &.featured {
          border-radius: 8px;
        }
        .result-type {
          margin-bottom: 18px;
        }
        .result-byline {
          margin-bottom: 6px;
        }
        .result-description {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .result-image-wrapper {
          display: block;
        }
      }
    }
  }
}

.hub-result-card:global(.hub-result-card-tall) {
  :global {
    @include media-breakpoint-up(lg) {
      .hub-result-card-container {
        padding: 14px;
        border-bottom: 0;
        margin-right: 10px;
        border-radius: 4px;
        // Let the box-shadow pass through the card borders.
        background-color: transparent;

        &:hover, &:focus {
          box-shadow: 0px 1px 24px rgba(0,0,0,0.12);

        }
        &:focus {
          border: 2px $deep-blue-500 solid;
        }

        .result-image-wrapper {
          position: initial;
          max-width: 100%;
          width: 100%;
        }

        .result-image {
          max-width: 100%;
          width: 100%;
        }

        .result-type {
          margin-top: 18px;
        }
        .result-description {
          height: 5rem;
          -webkit-line-clamp: 3;
          line-clamp: 3;

          p {
            color: $slate-1000;
            font-size: 16px;
          }
        }
      }
    }
    .result-more {
      display: none;
    }
  }
}
