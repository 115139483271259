@import '../../../styles/basics';
@value spinner from '../../01_atoms/Spinner/index.module.scss';

.dropdown-filters-form {
  &__clear {
    color: $turquoise-1000;
    display: inline-block;
    cursor: pointer;
    font-size: 16px;

    svg {
      height: 10px;
      width: 10px;
      margin-top: -1px;
      margin-left: 3px;

      & > g > path {
        stroke: $white;
      }
    }

    &:hover {
      color: $turquoise-1200;

      svg g g path {
        stroke: $turquoise-1000;
      }
    }
  }

  .spinner {
    display: block;

    span {
      border-color: $turquoise-1000 transparent transparent transparent;
    }
  }

  // IE11 doesn't understand middle alignment
  &__ie11-compat {
    .spinner,
    .clear {
      vertical-align: top;
      transform: translateY(50%);
    }
  }

  @include media-breakpoint-up(md) {
    :global {
      .field-type-select {
        width: 300px;
        display: inline-block;
        margin-right: 40px;
      }
    }

    &__clear {
      font-size: 18px;
    }

    .spinner {
      top: 5px;
      display: inline-block;
    }
  }
}
