@import '../../../../styles/basics';

.link-title {
  text-decoration: none !important;
  margin-top: -2px;
  @include media-breakpoint-up(md) {
    margin-top: -8px 0 8px 0;
  }

  .link-title-chevron {
    background: none;
    position: absolute;
    margin-top: 7px;
    margin-left: 6px;
    height: 12px;
    transition: margin-left 0.2s;
    @include media-breakpoint-up(md) {
      margin-top: 9px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 13px;
    }

    &.hover {
      margin-left: 10px;
    }

    path {
      fill: none;
    }
  }
}
