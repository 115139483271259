@import '../../../../../styles/basics';

.hub-search-filters {
  display: none;

  .filter-section {
    margin-bottom: 27px;
    position: relative;

    .loading-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.5);
    }

    h2 {
      font-weight: $font-weight-bold;
      font-size: 16px;
      margin-bottom: 15px;
    }

    .show-more {
      font-weight: $font-weight-bold;
      cursor: pointer;
      color: $turquoise-1200;
      text-decoration: underline;
      font-size: 14px;
      margin-left: 9px;

      &:focus,
      &:hover {
        text-decoration: none;
      }
    }

    .filter-checkbox {
      input[type='checkbox'] {
        display: none;

        + span {
          position: relative;
          line-height: $line-height-large;
        }

        &:checked + span::after {
          content: '';
          position: absolute;
          width: 10px;
          height: 6px;
          background: rgba(0, 0, 0, 0);
          top: 2px;
          left: 3px;
          border: 2px solid $white;
          border-top: none;
          border-right: none;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        + span::before {
          content: '';
          display: inline-block;
          height: 16px;
          width: 16px;
          background-color: $white;
          border: 2px solid rgba(0, 0, 0, 0.5);
          vertical-align: sub;
          margin-right: 10px;
          border-radius: 2px;
        }

        &:checked + span::before {
          border: none;
          background-color: $turquoise-1200;
        }

        &:checked + span {
          color: $turquoise-1200;
        }
      }

      label {
        display: block;
        border-radius: 4px;
        padding: 14px 5px 14px 31px;
        text-align: left;
        cursor: pointer;
        line-height: $line-height-normal;
        transition: background-color 0.2s ease-in-out;
        margin: 5px 0;
        font-size: 14px;
        color: $black;

        @include media-breakpoint-up(md) {
          padding: 8px 5px 8px 31px;
        }

        &:hover,
        &:focus {
          background-color: transparentize($turquoise-100, 0.6);
        }

        input[type='checkbox'] {
          &:checked + span::after {
            left: -19px;
            top: 6px;
          }

          + span::before {
            position: absolute;
            left: -22px;
            top: 2px;
          }

          &:checked + span {
            color: $black;
          }
        }

        .item-count {
          color: $slate-1000;
        }
      }

      &.checked label {
        background-color: transparentize($turquoise-100, 0.6);
      }
    }
  }

  .mobile-buttons {
    display: none;
  }

  &.mobile-filters-opened {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    background-color: $white;
    overflow: scroll;
    padding: 80px 72px 60px 32px;

    @include media-breakpoint-up(md) {
      position: relative;
      z-index: auto;
      overflow: auto;
      padding: 0;
    }

    .mobile-buttons {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 65px;
      background-color: $white;
      z-index: 1;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-up(md) {
        display: none;
      }

      .back-button {
        color: $turquoise-1200;
        display: inline-block;
        padding: 20px 20px 20px 25px;
        position: relative;
        margin-left: 24px;

        &:before {
          content: '';
          background-image: url('/static/icons/arrow.svg');
          background-size: contain;
          position: absolute;
          width: 14px;
          height: 14px;
          left: 0;
          top: 24px;
          display: inline-block;
        }
      }

      .clear-button {
        margin-right: 12px;
        color: $turquoise-1200;
        text-decoration: underline;
        padding: 20px;
      }

      .syntactic-apply-filters-button {
        position: fixed;
        bottom: 0;
        width: 100%;
        border-radius: 0;
        border: none;
        margin: 0;
        height: 63px;
        background-color: $turquoise-1200;
        font-weight: $font-weight-bold;
      }
    }
  }

  @include media-breakpoint-up(md) {
    display: block;
    flex: 0 0 25%;
  }
}
