@import '../../../styles/basics';
@value loading-text from '../../01_atoms/LoadingText/index.module.scss';

// "popover" is already a Bootstrap component class ...
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparentize($slate-1200, 0.1);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 10000;
  overflow-y: scroll;

  &:not(:global(.d-none)) {
    opacity: 1;
  }

  &:global(.loading-screen) > :global(.popup-navigation) {
    display: none;
  }

  .loading-text {
    font-weight: $font-weight-bold;
    font-size: 42px;
    color: $white;
  }
  :global {
    .popup-navigation {
      &:focus,
      &:focus-visible,
      &:focus-within {
        outline: none;
        outline-width: 0;
        box-shadow: none;
      }
    }

    .popup-navigation {
      position: absolute;
      z-index: 5000;
      left: 0;
      right: 0;
      top: 0;
      height: 108px;

      .close-icon {
        cursor: pointer;
        float: right;
        height: 44px;
        width: 44px;
        margin: 32px;
        position: relative;
        z-index: 100;
        background: none;
        border: none;

        circle,
        path {
          transition: stroke 0.2s;
        }

        svg {
          top: -1px;
          position: relative;
        }

        &:focus,
        &:focus-visible,
        &:focus-within {
          border-radius: 100%;
        }

        &:hover,
        &:focus {
          svg * {
            stroke: $biscuit-200;
          }
        }
      }
    }

    .popup-container {
      position: relative;
      z-index: 2000;
      padding: 108px 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0;
      max-width: none;

      & > * {
        margin: auto;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .loading-text {
      font-size: 64px;
    }
  }

  @include media-breakpoint-up(md) {
    .loading-text {
      font-size: 72px;
      color: $white;
    }
  }
}
