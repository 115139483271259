@import '../../../../../styles/basics';

$component-name: 'bb-heading-with-animation';

.#{$component-name} {
  text-align: center;

  &__element {
    strong {
      background: linear-gradient(to right, $yellow-800 50%, transparent 50%);
      background-size: 0 100%;
      background-repeat: no-repeat;
      transition: background-size .7s ease;
    }
  }

  &--highlight {
    .#{$component-name}__element {
      strong {
        background-size: 200% 100%;
      }
    }
  }
}
