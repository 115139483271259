@import '../../../../../styles/basics';
@value bb-hero as componentBBHero from '../index.module.scss';

.bb-hero-hub-home-page {
  &.componentBBHero {
    min-height: 240px;

    @include media-breakpoint-up(md) {
      min-height: 350px;
    }
  }

  :global {
    .overlay {
      padding-bottom: 35px;
      h1 {
        font-weight: $font-weight-bold;
        font-size: 20px;
        margin-bottom: 25px;
        line-height: $line-height-normal;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      }
      .description {
        font-weight: $font-weight-semi-bold;
        font-size: 16px;
        line-height: $line-height-large;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      }
      @include media-breakpoint-up(md) {
        padding-bottom: 90px;
        h1 {
          font-size: 44px;
          margin-bottom: 14px;
        }
        .description {
          font-size: 24px;
        }
      }
    }

    .hub-home-page-gradient,
    .hub-home-page-background-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .hub-home-page-background-image {
      background-image: url(/static/images/hub_home_hero_bkgnd_mobile.png);
      background-size: cover;
      padding: 0 20px 20px 0;
      @include media-breakpoint-up(md) {
        background-image: url(/static/images/hub_home_hero_bkgnd.png);
      }
      @include media-breakpoint-up(lg) {
        &__wrapper {
          max-width: 1304px;
          width: 100%;
          height: 100%;
          margin: 0 auto;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0;
          &:after {
            background-image: url(/static/images/symbol_for_hub_home_hero.svg);
            background-repeat: no-repeat;
            width: 200px;
            height: 200px;
            display: block;
            content: ' ';
            opacity: 0.6;
          }
        }
      }
    }
  }
}
