@import '../../../../styles/basics';

.paginator-mini {
  height: 44px;
  width: 104px;

  :global {
    .counter {
      display: inline-block;
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    .arrow {
      display: inline-block;
      background: url('/static/icons/carousel-button.svg') no-repeat;
      width: 44px;
      height: 44px;
      cursor: pointer;
      transition: 0.3s opacity;

      &:hover {
        opacity: 0.9;
      }

      &.disabled,
      &.loading {
        background: url('/static/icons/carousel-button-inactive.svg') no-repeat;
        cursor: default;

        &:hover {
          opacity: 1;
        }
      }

      &.prev {
        margin-right: 8px;
        -webkit-transform: rotate(180deg) translateY(0);
        -moz-transform: rotate(180deg) translateY(0);
        -ms-transform: rotate(180deg) translateY(0);
        -o-transform: rotate(180deg) translateY(0);
        transform: rotate(180deg) translateY(0);
      }

      &.next {
        margin-left: 8px;
        -webkit-transform: rotate(360deg) translateY(0);
        -moz-transform: rotate(360deg) translateY(0);
        -ms-transform: rotate(360deg) translateY(0);
        -o-transform: rotate(360deg) translateY(0);
        transform: rotate(360deg) translateY(0);
      }
    }
  }
}
