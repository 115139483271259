@import '../../../../../styles/basics';

.no-results-links {
  padding: 20px 0;
  text-align: left;

  &__explore-block {
    display: inline-block;
    text-align: left;
  }

  &__title {
    margin-bottom: 20px;
  }
  &__link-item {
    display: block;
    margin-bottom: 26px;
  }
  &__search-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;

    path:last-child {
      fill: $slate-600;
    }
  }

  &__link {
    display: inline-block;
    line-height: 24px;
    text-decoration: underline 1px solid $turquoise-1200;
    text-underline-offset: 5px;
    &:hover {
      text-decoration: underline 2px solid $turquoise-1200;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 40px 0;
    text-align: center;
  }
}
