@import '../../../../styles/basics';

.bb-detail-meta {
  @include bb-standalone-text-spacing();

  :global {
    .container {
      position: relative;

      .row > .centered-layout-mode {
        margin-bottom: 0;
        @include media-breakpoint-up(lg) {
          padding-left: 80px;
        }
      }

      .meta {
        position: relative;

        [data-component="tag-text"],
        .date,
        .author {
          display: block;
          height: 28px;
          line-height: $line-height-normal;
          font-size: 14px;
          padding-top: 5px;
          padding-bottom: 5px;

          > * {
            padding: 0;
            line-height: $line-height-normal;
          }
        }

        [data-component="tag-text"] {
          display: inline-block;
          margin-right: 24px;
          margin-bottom: 5px;
          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }
        }

        .date {
          display: block;
          @include media-breakpoint-up(md) {
            display: inline-block;
            padding-right: 24px;
            border-right: 1px solid $slate-200;
          }
        }

        .author {
          display: block;

          @include media-breakpoint-up(md) {
            display: inline-block;
            margin-left: 24px;
          }
        }

        .separator-v {
          position: absolute;
          height: 1px;
          background: $slate-200;
          left: 0;
          right: 0;
          bottom: 61px;
          @include media-breakpoint-up(md) {
            bottom: 0;
          }
        }
      }
    }

    .button-container {
      position: absolute;
      top: 10px;
      right: 12px;
      z-index: 100;

      [data-component^="button-"] {
        margin: 0;
      }
    }
  }
}
