@import '../../../../styles/basics';

.link-chevron {
  padding-top: 16px;
  font-size: 16px;
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }

  &:hover {
    text-decoration: none;

    svg {
      right: -4px;
    }
  }

  svg {
    width: 5px;
    height: 9px;
    position: relative;
    right: -2px;
    top: -1px;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -o-transition: 0.3s all;
    transition: 0.3s all;
    @include media-breakpoint-up(md) {
      top: auto;
    }

    path {
      stroke: $turquoise-1000;
      fill: none !important;
    }
  }

  &.bold svg {
    width: 6px;
  }

  &.left {
    padding-left: 8px;

    svg {
      left: -8px;
    }

    &:hover {
      svg {
        left: -6px;
      }
    }
  }

  &.size-small {
    font-size: 14px;
    line-height: 2;

    svg {
      width: 4px;
    }
  }
}
