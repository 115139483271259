@import '../../../../../styles/basics';

.bb-inline-cta {
  @include bb-standalone-text-spacing();

  &--align-center {
    text-align: center;
  }

  &__button {
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}
