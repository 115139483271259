@import '../../../../styles/basics';
@import '../../../../styles/mixins/responsive-image';

.bb-hero {
  position: relative;
  overflow: hidden;
  background-color: $slate-1200;
  padding-bottom: 100px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  min-height: 90px;

  @include media-breakpoint-up(sm) {
    padding-bottom: 60px;
  }

  @include media-breakpoint-up(md) {
    padding-bottom: 0;
    min-height: 390px;
  }

  :global {
    // Handling image / video AR
    .image-box {
      flex-grow: 1;
      position: relative;
      background-color: $slate-1200;
      height: 0;

      img,
      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .gradient {
      position: absolute;
      width: 100%;
      bottom: 100px;
      height: 150px;
      background-image: linear-gradient(
        to bottom,
        rgba(33, 36, 49, 0),
        rgba(33, 36, 49, 0.79) 61%,
                      $slate-1200
      );
      @include media-breakpoint-up(sm) {
        bottom: 60px;
      }
      @include media-breakpoint-up(md) {
        bottom: 0;
        height: 280px;
        background-image: linear-gradient(
          to bottom,
          rgba(33, 36, 49, 0),
          rgba(33, 36, 49, 0.95) 90%
        );
      }
    }

    .container-wrapper {
      position: absolute;
      width: 100%;
      bottom: 0;
    }

    .overlay {
      // override standard _grid spacing
      margin-bottom: 0 !important;
      padding-bottom: 40px;
      @include media-breakpoint-up(md) {
        padding-bottom: 60px;
      }

      * {
        color: $white !important;
        text-shadow: 1px 1px 1px $black;
      }

      [data-component^="button-"] {
        text-shadow: none;
      }

      .description,
      p {
        font-size: 22px;
        margin: 0;
      }
    }
    @include responsive-image();
  }
}
