@import '../../../../styles/basics';

.search-input {
  display: flex;
  position: relative;
  width: 100%;
  z-index: 0;

  .input-wrapper {
    flex-grow: 1;
    position: relative;
    margin-right: -1px;
    &:before {
      content: '';
      background-image: url('/static/icons/knowledge-hub-search.svg');
      background-size: contain;
      position: absolute;
      width: 24px;
      height: 24px;
      left: 14px;
      top: 17px;
      display: inline-block;
      z-index: 1;
    }

    input {
      width: 100%;
      height: 56px;
      border: solid 2px $slate-400;
      margin: -1px;
      border-radius: 4px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      margin-right: 180px;
      margin-top: 0;
      padding-left: 42px;
      background-color: $biscuit-100;
      box-shadow: none;
      border-right: none;
      font-size: $font-size-lg;

      &:focus,
      &:focus-visible,
      &:active {
        outline: none;
        border: solid 2px $turquoise-1000;
        box-shadow: 0 0 0 3px $turquoise-100;
        border-right: none;
      }

    }

    .clear-search-button {
      z-index: 1;
      right: 0;
      top: 0;
      position: absolute;
      padding: 27px 21px 15px;
      cursor: pointer;

      .clear-desktop {
        display: none;
        height: 56px;
        justify-content: center;
        align-items: center;
        font-weight: $font-weight-semi-bold;
        color: $turquoise-1200;

        @include media-breakpoint-up(md) {
          display: flex;
        }
      }

      @include media-breakpoint-up(md) {
        padding: 0 28px 0 0;
      }
    }
  }

  .search-submit-button {
    display: block;
    //background-color: $turquoise;
    height: 56px;
    width: 130px;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    font-weight: $font-weight-semi-bold;
    margin-right: 0;
    margin-bottom: 0;
  }
}

.search-mobile {
  position: fixed;
  z-index: 1;

  .input-wrapper {
    &::before {
      display: none;
    }

    input {
      width: 100%;
      height: 70px;
      padding-left: 50px;
      border: none;
      background-color: $white;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
      // box-shadow doesn't display properly on IOS, it works with -webkit-appearance: none;
      -webkit-appearance: none;
      &:focus,
      &:focus-visible,
      &:active {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
        outline: none;
        border: none;
      }
    }

    .clear-search-button {
      padding: 8px 10px;
      .clear-mobile {
        content: '';
        background-image: url('/static/icons/clear-mobile-search.svg');
        background-repeat: no-repeat;
        background-position: center;
        padding: 28px;
      }
    }
  }

  .back-button {
    background-image: url('/static/icons/arrow-left.svg');
    background-size: 36px;
    background-position: center;
    background-repeat: no-repeat;
    width: 50px;
    height: 70px;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    top: 2px;
  }
}
