@import '../../../../styles/basics';

.bb-teaser-with-pager {
  @include bb-standalone-spacing();

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;

    &.bb-teaser-with-pager--mobile-scroll {
      @include media-breakpoint-down(lg) {
        flex-wrap: nowrap;
        overflow-x: auto;
      }
    }
  }

  &__item {
    padding-left: 12px;
    padding-right: 12px;
  }

  &__upper {
    display: flex;
    margin-bottom: 18px;
    @include media-breakpoint-up(md) {
      margin-bottom: 24px;
    }

    h2 {
      flex-grow: 1;
      align-self: center;
    }

    :global(.link-chevron) {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
        max-width: 170px; // ensures pagination buttons don't line-break when link title is long
        text-align: right;
        padding-top: 12px;
        margin: 0 32px;
      }
      @include media-breakpoint-up(lg) {
        max-width: none;
      }
    }

    :global(.paginator-mini) {
      display: none;
      @include media-breakpoint-up(md) {
        display: flex;
        align-self: center;
      }

      :global(.spinner) {
        left: 40px;
        top: 10px;

        span {
          border-color: $turquoise-1000 transparent transparent transparent;
        }
      }

      :global(.arrow.loading) {
        display: none;
      }
    }
  }

  &__bottom-line {
    @include media-breakpoint-up(md) {
      display: none;
    }

    [data-component^="button"]{
      margin-top: 36px;
    }

    :global(.paginator-mini) {
      width: 100%;
      position: relative;

      :global(.spinner) {
        top: 10px;
        display: block;
        margin: 0 auto;

        span {
          border-color: $turquoise-1000 transparent transparent transparent;
        }
      }

      :global(.counter) {
        display: block;
        text-align: center;
        margin: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 8px;
        z-index: 5;
      }

      :global(.arrow) {
        position: absolute;
        top: 0;
        z-index: 6;

        &:global(.prev) {
          left: calc(50% - 80px);
        }

        &:global(.next) {
          right: calc(50% - 80px);
        }
      }
    }
  }

  :global(.item-with-image) {
    :global(.title) {
      font-size: 16px;
      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }

    :global(.description) {
      p {
        margin-bottom: 0;
      }
    }

    &:global(.resources-list .top) {
      background: $turquoise-1000;
      color: $white;

      @media all and (min-resolution: 1x) {
        img {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
        }
        // 300x180 image. 180 / 300 * 100 ~ 60
        padding-top: 60%;
      }
    }
  }

  :global(.item-with-image.related-documents .top) {
    background: $turquoise-1000;
    color: $white;

    @media all and (min-resolution: 1x) {
      img {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
      }
      // 420x230 image. 230 / 420 * 100 ~ 56.09
      padding-top: 56.09%;
    }
  }

  :global(.top .bottom-line) {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
