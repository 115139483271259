@import '../../../../styles/basics';

.search-input-mobile-popup {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: $white;
  z-index: 9999;
  overflow-y: auto;
}
