@import '../../../../../styles/basics';
@import '../../../../../styles/mixins/hero';

@keyframes :local(heroOverlayAnimation) {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.bb-hero-homepage-branded-tagline {
  &:global(.bb) {
    @include media-breakpoint-down(md) {
      padding-bottom: 0;
    }
  }

  :global {
    // Handling image / video AR
    .image-box {
      padding-bottom:  100%; // // AR of 360x360
      @include media-breakpoint-up(sm) {
        padding-bottom: 56.25%; // AR of 992x558 (16:9)
      }
      @include media-breakpoint-up(lg) {
        padding-bottom: 39.29%; // AR of 1440x550
      }
    }

    .responsive-image img {
      height: 100%;
    }

    .container-wrapper {
      min-height: 240px;
      display: flex;
      align-items: flex-end;
      background: linear-gradient(to top, transparentize($slate-1200, 0.4) 0% 50%, transparentize($slate-1200, 1) 100%);
      @include media-breakpoint-up(lg) {
        align-items: center;
        top: 0;
        background: linear-gradient(to right, transparentize($black, 0.5) 0% 25%, transparent 60%);
      }

      .bb-hero-homepage-branded-tagline--animated-tagline {
        &:local{
          animation: heroOverlayAnimation 1.5s 1 cubic-bezier(.165,.84,.44,1);
        }
        margin-bottom: 24px;
        max-width: 270px;

        @include media-breakpoint-up(md) {
          max-width: 440px;
        }
      }
    }
  }
}


