@import '../../../../../styles/basics';

.sort-wrapper {
  display: flex;

  .sort-item {
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .sort-by-latest {
    margin-right: 20px;

  }

  .active {
    color: $turquoise-1200;
    cursor: default;
    padding-bottom: 5px;
    border-bottom: solid 2px $turquoise-1200;

    svg path {
      stroke: $turquoise-1200;
    }
  }

  &.hub-homepage-sort {
    z-index: 1;
    margin-top: 20px;
    margin-bottom: -2px;

    @include media-breakpoint-up(md) {
      margin-bottom: -7px;
      margin-top: 0;
    }

    .active {
      @include media-breakpoint-up(md) {
        padding-bottom: 15px;
      }
    }
  }
}
