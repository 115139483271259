@import '../../../../../styles/basics';

.bb-hero-landing {
  h1 {
    margin-bottom: 6px;
  }

  @include media-breakpoint-up(md) {
    h1 {
      margin-bottom: 0;
    }
  }
}
