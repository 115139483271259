@import '../../../styles/basics';

.youtube-video-in-popup {
  background-image: url('/static/icons/loading.svg');
  background-position: center;
  background-repeat: no-repeat;

  :global {
    .container {
      padding: 0;

      .poster {
        max-width: 780px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;

        .cookie-consent-context-dialog {
          position: relative;
          padding: 30px 0;

          @include media-breakpoint-up(md) {
            padding: 30px 80px;
          }
        }
      }
    }
  }
}
