@import '../../../../../styles/basics';

.bb-text {
  @include bb-text-spacing();

  &.bb-text-large {
    font-size: 18px;
    @include media-breakpoint-up(md) {
      @include bb-standalone-text-spacing();
      font-size: 28px;
    }

    p {
      font-size: 18px;
      @include media-breakpoint-up(md) {
        font-size: 28px;
      }
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}

:global(.bb-heading) {
  & + .bb-text-large {
    margin-top: $bb-mobile-text-margin;
    @include media-breakpoint-up(md) {
      margin-top: $bb-desktop-text-margin;
    }
  }
}
