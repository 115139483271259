@import '../../../../../styles/basics';
@import '../../../../../styles/mixins/hero';
@value bb-hero as componentBBHero from '../index.module.scss';

.bb-hero-image {
  &.componentBBHero {
    padding-bottom: 0;
    background: transparent;
    margin-bottom: $bb-mobile-standalone-text-margin;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  :global {
    .image-container {
      position: relative;
      background-color: $slate-1200;
      min-height: 90px;
      @include media-breakpoint-up(md) {
        min-height: 390px;
      }
    }

    .gradient-mobile {
      height: 80px;
      bottom: 0;
      top: auto;
    }

    .breadcrumbs {
      margin-bottom: 8px;
    }

    .header-one {
      margin-top: 16px;
    }

    .header-two {
      @include media-breakpoint-down(md) {
        margin-top: 8px;
      }
    }

    .container {
      @include media-breakpoint-down(md) {
        transform: none;
      }
    }

    @include hero-dec();
  }
}
