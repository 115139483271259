@import '../../../../../styles/basics';
@import '../../../../../styles/mixins/hero';
@value bb-hero as componentBBHero from '../index.module.scss';

.bb-hero-emergency {
  &.componentBBHero {
    min-height: 300px;
    background-color: $black;

    @include media-breakpoint-up(md) {
      min-height: 400px;
    }
  }

  :global {
    .gradient {
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.79) 81%,
        $black
      );

      @include media-breakpoint-up(md) {
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.66) 50%,
          $black
        );
      }
    }

    .overlay {
      [data-component="tag-text"] {
        margin-bottom: 12px;

        // override desktop Tag styling, use same as mobile display
        @include media-breakpoint-up(md) {
          margin-bottom: 20px;
        }

        * {
          text-shadow: none;
        }
      }

      h1 {
        margin-bottom: 12px;
      }

      [data-component^="button-"] {
        margin-top: 20px;
        margin-bottom: 0;
      }
    }

    @include hero-dec();
  }
}
