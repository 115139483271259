@mixin responsive-image {
  .responsive-image {
    width: 100%;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;

      // Height can be overwritten by other styles for particular component.
      // IE doesn't support 'object-fit' property, so always use height: auto
      // in case of IE browser to avoid stretched images.
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        /* IE10+ specific styles go here */
        height: auto !important;
      }

      // Fix a glitch with narrow line below frayed edge.
      // See https://www.pivotaltracker.com/story/show/172528224/comments/214250090
      margin-bottom: 1px;
    }

    // Extra visibility classes are used to hide or show hero images with
    // different image ratios, e.g. 3:2 on mobile and 16:9 on tablet.
    // We can do it with aspect-ratio CSS property when it's widely supported.
    &.responsive-image-mobile {
      @media (min-width: 421px) {
        display: none;
      }
    }
    &.responsive-image-tablet {
      display: none;
      @media (min-width: 421px) and (max-width: 992px) {
        display: block;
      }
    }
    &.responsive-image-desktop {
      display: none;
      @media (min-width: 993px) {
        display: block;
      }
    }
  }
}
