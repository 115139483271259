@import '../../../../../styles/basics';

.bb-heading {
  &:global(.h1) {
    margin: 24px 0;

    @include media-breakpoint-up(md) {
      margin: 40px 0;
    }

    @include media-breakpoint-up(lg) {
      margin: 56px 0;
    }
  }

  &:global(.h2) {
    margin: 40px 0 16px;

    @include media-breakpoint-up(md) {
      margin: 40px 0 24px;
    }

    @include media-breakpoint-up(lg) {
      margin: 56px 0 40px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 !important;
  }
}
