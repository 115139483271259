@import '../../../../../styles/basics';
@import '../../../../../styles/mixins/hero';
@value formatted-text from '../../../../01_atoms/FormattedText/index.module.scss';

@keyframes :local(heroOverlayAnimation) {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.bb-hero-white-font-strapline {
  &:global(.bb) {
    padding-bottom: 0;
    @include media-breakpoint-between(xs, sm) {
      min-height: 325px;
    }
  }

  :global {
    // Handling image / video AR
    .image-box {
      padding-bottom:  100%; // // AR of 360x360
      @include media-breakpoint-up(sm) {
        padding-bottom: 56.25%; // AR of 992x558 (16:9)
      }
      @include media-breakpoint-up(lg) {
        padding-bottom: 39.29%; // AR of 1440x550
      }
    }

    .responsive-image img {
      height: 100%;
    }

    .container-wrapper {
      min-height: 240px;
      display: flex;
      align-items: flex-end;
      background: linear-gradient(to top, transparentize($slate-1200, 0.4) 0% 50%, transparentize($slate-1200, 1) 100%);
      @include media-breakpoint-up(lg) {
        align-items: center;
        top: 0;
        background: linear-gradient(to right, transparentize($black, 0.5) 0% 25%, transparent 60%);
      }
      .bb-hero-white-font-strapline--animated-text {
        color: $white;
        max-width: 570px;
        margin-bottom: 24px;
        animation: heroOverlayAnimation 1.5s 1 cubic-bezier(.165,.84,.44,1);

        @include media-breakpoint-up(lg) {
          max-width: 640px;
        }

        [data-component="formatted-text"] {
          color: $white;
          font-weight: $font-weight-regular;
          font-size: 22px;
          line-height: $line-height-large;
          margin-bottom: 10px;

          @include media-breakpoint-up(md) {
            font-size: 32px;
          }
          @include media-breakpoint-up(lg) {
            font-size: 44px;
          }
        }

        .link-chevron {
          font-size: $font-size-base;
          color: $white;
          text-decoration: underline;
          text-underline-offset: 6px;
          padding-top: 0;
          strong {
            font-weight: $font-weight-regular;
          }
          svg {
            right: -5px;
            width: 7px;
            height: 9px;
            top: 1px;
            @include media-breakpoint-up(md) {
              width: 9px;
              height: 13px;
              right: -8px;
              top: 1px;
            }
            path {
              stroke: $white;
            }
          }

          &:hover,
          &:focus {
            text-decoration-thickness: 3px;
            svg {
              right: -7px;
              @include media-breakpoint-up(md) {
                right: -10px;
              }
            }
          }

          @include media-breakpoint-up(md) {
            margin-top: 16px;
            font-size: 22px;
          }
        }
      }
    }
  }
}


