@import '../../../../styles/basics';

.tag-text {
  display: inline-block;
  font-size: 11px;
  line-height: 18px;
  padding: 3px 5px;
  background-color: $slate-1200;

  &__background--red {
    background-color: $red-800;
  }

  &__background--black-emergency {
    background-color: $black;
  }

  &__border--red {
    border: 1px solid $red-800;
  }

  &__label {
    color: $white;

    &--upper {
      text-transform: uppercase;
    }

    &--black-emergency {
      color: $black;
    }

    &--red {
      color: $red-800;
    }
  }

  @include media-breakpoint-up(md) {
    font-size: 14px;
    padding: 5px 10px;
  }
}
