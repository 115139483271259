@import '../../../../styles/basics';
@import '~bootstrap/scss/mixins/grid';

.bb-related-content {
  @include bb-standalone-spacing();

  &__button-wrapper {
    text-align: center;
  }

  &--bg-color-white {
    background: $white;
  }

  &--bg-color-biscuit {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: $bb-mobile-standalone-margin;
    padding-bottom: $bb-mobile-standalone-margin;
    background: $biscuit-600;
    @include media-breakpoint-up(md) {
      padding-top: 48px;
      padding-bottom: 24px;
    }
  }

  &__heading {
    display: flex;
    justify-content: space-between;

    h2 {
      @include bb-heading-spacing();
    }

    :global(.link-chevron) {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;

    &--collapsed {
      display: none;
    }
  }

  &__item {
    @include make-col(12);
    margin-bottom: 40px;
  }

  :global(.main-link) {
    &:hover,
    &:focus-visible {
      h3 {
        text-decoration: underline;
      }
    }
  }

  [data-component^="button"] {
    margin: 40px 0 0;
    @include media-breakpoint-up(md) {
      margin-top: 24px;
    }
  }

  :global(.slider) {
    :global(.counter) {
      text-align: center;
      margin-bottom: 8px;
    }

    :global(.slick-slider) {
      padding-bottom: 32px;

      :global(.slick-arrow) {
        background: url('/static/icons/carousel-button.svg') no-repeat;
        background-size: 40px;
        width: 40px;
        height: 40px;
        bottom: -52px;
        top: auto;

        &:before {
          display: none;
        }

        &:global(.slick-prev) {
          left: calc(50% - 80px);
          -webkit-transform: rotate(180deg) translateY(20px);
          -moz-transform: rotate(180deg) translateY(20px);
          -ms-transform: rotate(180deg) translateY(20px);
          -o-transform: rotate(180deg) translateY(20px);
          transform: rotate(180deg) translateY(20px);
        }

        &:global(.slick-next) {
          right: calc(50% - 80px);
          -webkit-transform: rotate(360deg) translateY(-20px);
          -moz-transform: rotate(360deg) translateY(-20px);
          -ms-transform: rotate(360deg) translateY(-20px);
          -o-transform: rotate(360deg) translateY(-20px);
          transform: rotate(360deg) translateY(-20px);
        }

        &:global(.slick-disabled) {
          background-image: url('/static/icons/carousel-button-inactive.svg');
        }
      }
    }
  }

  :global(.item-with-image) .top {
    background: $turquoise-1000;
    color: $white;

    @media all and (min-resolution: 1x) {
      img {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
      }
      // 400x220 image. 220 / 400 * 100 ~ 55
      padding-top: 55%;
    }
  }

  @include media-breakpoint-up(md) {
    &__items--collapsed {
      display: flex;
    }

    &__item {
      @include make-col(4);
      padding: 0 12px;
    }
  }

  @include media-breakpoint-up(lg) {
    &__item {
      margin-bottom: 24px;
    }
  }
}
