@import '../../../styles/basics';

.tagline {
  overflow: hidden;

  &__label {
    text-transform: uppercase;
    display: inline;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    font-weight: $font-weight-bold;
    position: relative;
    word-break: break-word;

    &:before {
      display: block;
      content: '';
      position: absolute;
      right: 100%;
      top: 0;
      width: 0;
      height: 0;
      border-left-style: solid;
      border-left-color: transparent;
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 100%;
      bottom: 0;
      width: 0;
      height: 0;
      border-right-style: solid;
      border-right-color: transparent;
    }
  }

  &__size {
    &-small {
      padding-left: 11px; // decoration width: ~ (27px / 4) angle + 4px padding
      padding-right: 11px;
      font-size: 16px;
      line-height: 26px;

      .tagline__label {
        font-size: 16.6px;
        line-height: 23px;
        padding: 2px 4px;
        margin-right: -4px;
        margin-left: -4px;

        &:before {
          border-bottom-style: solid;
          border-bottom-width: 27px;
          border-left-width: calc(27px / 4); // determines angle: height of the line / ctg of angle
        }

        &:after {
          border-bottom-style: solid;
          border-bottom-width: 27px;
          border-right-width: calc(27px / 4);
        }
      }
    }

    &-big {
      padding-left: 13px; // decoration width: ~ (37px / 8) angle + 8px padding
      padding-right: 13px;
      font-size: 35px;
      line-height: 24px;

      .tagline__label {
        font-size: 21px;
        line-height: 35px;
        padding: 4px 8px;
        margin-right: -8px;
        margin-left: -8px;

        &:before {
          border-top-style: solid;
          border-top-width: 37px;
          border-left-width: calc(37px / 8); // determines angle: height of the line / ctg of angle
        }

        &:after {
          border-top-style: solid;
          border-top-width: 37px;
          border-right-width: calc(37px / 8);
        }
      }
    }
  }

  &__bg {
    &-white {
      .tagline__label {
        background: $white;
      }

      &.tagline__size-small .tagline__label {
        &:before,
        &:after {
          border-bottom-color: $white;
        }
      }

      &.tagline__size-big .tagline__label {
        &:before,
        &:after {
          border-top-color: $white;
        }
      }
    }

    &-green {
      .tagline__label {
        background: $green-800;
      }

      &.tagline__size-small .tagline__label {
        &:before,
        &:after {
          border-bottom-color: $green-800;
        }
      }

      &.tagline__size-big .tagline__label {
        &:before,
        &:after {
          border-top-color: $green-800;
        }
      }
    }
  }

  &__text {
    &-teal {
      .tagline__label {
        color: $turquoise-600;
      }
    }
    &-white {
      .tagline__label {
        color: $white;
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__size {
      &-big {
        padding-left: 20px; // decoration width: ~ (62px / 8) angle + 12px padding
        padding-right: 20px;
        font-size: 59px;
        line-height: 42px;

        .tagline__label {
          font-size: 36.1px;
          line-height: 58px;
          padding: 6px 12px;
          margin-right: -12px;
          margin-left: -12px;

          &:before {
            border-top-width: 62px;
            border-left-width: calc(62px / 8); // determines angle: height of the line / ctg of angle
          }

          &:after {
            border-top-width: 62px;
            border-right-width: calc(62px / 8);
          }
        }
      }
    }

    &__inverted {
      &.tagline__text-white {
        .tagline__label {
          background: $white;
        }
        &.tagline__size-small .tagline__label {
          &:before,
          &:after {
            border-bottom-color: $white;
          }
        }

        &.tagline__size-big .tagline__label {
          &:before,
          &:after {
            border-top-color: $white;
          }
        }
      }
      &.tagline__text-teal {
        .tagline__label {
          background: $turquoise-600;
        }
        &.tagline__size-small .tagline__label {
          &:before,
          &:after {
            border-bottom-color: $turquoise-600;
          }
        }

        &.tagline__size-big .tagline__label {
          &:before,
          &:after {
            border-top-color: $turquoise-600;
          }
        }
      }

      &.tagline__bg-white .tagline__label {
        color: $white;
      }

      &.tagline__bg-green .tagline__label {
        color: $green-800;
      }
    }
  }
}
