@import '../../../../styles/basics';

.item-with-image {
  :global {
    .top {
      display: block;
      position: relative;
      overflow: hidden;
      margin-bottom: 12px;
      @include media-breakpoint-up(md) {
        margin-bottom: 16px;
      }

      img {
        width: 100%;
      }

      .gradient {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        height: 63px;
        background-image: linear-gradient(to bottom, rgba(33, 36, 49, 0), $slate-1200);
      }

      .bottom-line {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;

        .title {
          text-shadow: 1px 1px 1px $slate-1200;
          font-weight: $font-weight-bold;
          color: $white !important;
          font-size: 18px;
          padding: 8px 12px;
          margin: 0;
          @include media-breakpoint-up(md) {
            padding: 10px 20px;
            font-size: 22px;
          }
        }
      }
    }

    a.main-link {
      display: block;

      &:hover,
      &.hover,
      &:focus-visible {
        text-decoration: none;

        img {
          transform: scale(1.025);
        }

        h3,
        .title {
          color: $turquoise-1200;
          text-decoration: underline;
        }

        .description {
          color: $slate-1200;

          p {
            color: $slate-1200;
          }
        }
      }
    }

    .date {
      display: inline-block;
      padding-bottom: 4px;
    }

    .title {
      margin-bottom: 4px;
      color: $turquoise-1000;

      &.mobile-padding {
        margin-bottom: 32px;
        @include media-breakpoint-up(md) {
          margin-bottom: 4px;
        }
      }
    }

    .description {
      font-size: 18px;
      color: $slate-1200;

      p {
        margin-bottom: 0;
      }
    }
  }
}
