@import '../../../../../styles/basics';
@import '../../../../../styles/mixins/hero';

.bb-hero-emergency-overlay {
  &:global(.bb) {
    background-color: transparent;
    margin-bottom: $bb-desktop-standalone-margin-new;
    @include media-breakpoint-down(xl) {
      padding-bottom: 0;
      margin-bottom: $bb-mobile-standalone-margin-new;
    }
  }

  :global {
    // Handling image / video AR
    // TODO: Update image styles when backend part is done.
    .image-box {
      padding-bottom:  66.66%; // AR of 420x280
      @include media-breakpoint-up(md) {
        padding-bottom: 39.29%; // AR of 1440x550
      }
      @include media-breakpoint-up(lg) {
        padding-bottom: 56.25%; // AR of 992x558 (16:9)
      }
      @include media-breakpoint-up(xl) {
        padding-bottom: 39.29%; // AR of 1440x550
      }
    }

    .responsive-image img {
      height: 100%;
    }

    .container-wrapper {
      position: relative;
      background-color: $black;
      @include media-breakpoint-up(lg) {
        background-color: transparent;
      }
    }

    .bb-hero-emergency-overlay__overlay-wrapper {
      min-height: 60px;
      position: relative;

      .bb-hero-emergency-overlay__overlay {
        width: 100%;
        background-color: $black;
        position: relative;
        padding-top: 22px;
        padding-bottom: 6px;

        @include media-breakpoint-up(sm) {
          padding: 20px 20px 12px;
        }

        @include media-breakpoint-up(lg) {
          position: absolute;
          bottom: 0;
          left: 0;
          border-bottom: 8px solid $red-800;
          max-width: 710px;
          padding: 40px 40px 13px;

          &.bb-hero-emergency-overlay__overlay--right {
            right: 0;
            left: auto;
          }
        }

        h1 {
          font-size: 24px;
          font-weight: $font-weight-bold;
          color: $white;
          margin: 16px 0;

          @include media-breakpoint-up(md) {
            margin-top: 20px;
            margin-bottom: 12px;
            font-size: 30px;
            line-height: $line-height-large;
          }
        }

        p {
          font-size: 16px;
          color: $white;
          margin-bottom: 20px;

          @include media-breakpoint-up(md) {
            font-size: 22px;
          }
        }

        [data-component="tag-text"] {
          font-size: 14px;

          @include media-breakpoint-up(md) {
            font-size: 16px;
          }
        }
      }
    }

    .bb-hero-emergency-overlay__red-strip {
      background: $red-800;
      padding: 12px 0;

      @include media-breakpoint-up(lg) {
        padding: 16px;
        position: absolute;
        top: 0;
        left: 0;
        // Should be the same value as 'min-height' for '.bb-hero-emergency-overlay__overlay-wrapper'.
        bottom: 60px;

        &.bb-hero-emergency-overlay__red-strip--left {
          left: auto;
          right: 0;
        }
      }
    }

    .bb-hero-emergency-overlay__dec-logo-mobile {
      @include media-breakpoint-up(sm) {
        padding: 0 20px;
      }
    }
  }

  [data-component="tag-text"] {
    color: green;
  }
}


