@import '../../../../../styles/basics';
@import '../../../../../styles/mixins/hero';

.bb-hero-tagline {
  &:global(.bb) {
    padding-bottom: 0;
    background: transparent;
    margin-bottom: $bb-mobile-standalone-text-margin;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  &--two-lines {
    padding-bottom: 35px; // 35px is tagline height (1 line)
    @include media-breakpoint-up(md) {
      padding-bottom: 0;
    }
  }

  :global {
    .container-wrapper {
      @include media-breakpoint-up(md) {
        bottom: 90px;
      }

      .container {
        display: flex;
        justify-content: center;
      }
    }

    .image-box {
      padding-bottom: 40%; // // AR of 420x168
      @include media-breakpoint-up(md) {
        padding-bottom: 56.25%; // AR of 992x558 (16:9)
      }
      @include media-breakpoint-up(lg) {
        padding-bottom: 39.29%; // AR of 1440x550
      }
    }

    .title-wrapper h1 {
      width: calc(100% + 16px); // Compensation of tagline negative margins
      margin-left: -4px;
      margin-right: -4px;

      @include media-breakpoint-up(md) {
        width: calc(100% + 40px); // Compensation of tagline negative margins
        margin-left: -10px;
        margin-right: -10px;
      }
    }

    .title--line2 {
      padding-left: 48px;
      @include media-breakpoint-up(md) {
        padding-left: 80px;
      }
    }
  }
}
