@import '../../../../../styles/basics';
@value button-secondary from '../../../Button/index.module.scss';

.no-results-block {
  width: 100%;
  padding: 20px 0 20px 0;
  background-color: $biscuit-100;
  border-radius: 16px;

  [data-component="button-secondary"] {
    font-weight: $font-weight-bold;
    font-size: 16px;
    border-radius: 4px;
    margin: 0;
    background-color: $turquoise-1000;
  }

  &__icon {
    position: relative;
    display: inline-block;
    margin-bottom: 18px;

    &-search {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    &-clipboard {}
  }

  &__text {
    margin-bottom: 14px;
    padding: 0 15%;
    p {
      line-height: $line-height-normal;
      font-size: $font-size-base;
      margin-bottom: 18px;
    }

    strong {
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 40px 0;

    &__text {
      margin-bottom: 25px;
      padding: 0;
    }
  }
}
