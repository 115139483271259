@import '../../../../../styles/basics';

.hub-search-no-results {
  :global {
    .hub-search-no-results-container {
      text-align: center;
    }

    .hub-search-no-results-result {
      text-align: left;
      margin-bottom: 20px;
    }
  }
}
