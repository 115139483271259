@import '../../../../styles/basics';

.suggested-searches {
  margin-top: 70px;
  padding: 23px 28px;
  overflow-y: auto;

  @include media-breakpoint-up(md) {
    margin-top: 40px;
    padding: 0;
    padding-left: 3px;
    margin-left: -3px;
  }

  .title {
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
    color: $slate-1200;
    margin-bottom: 16px;

    @include media-breakpoint-up(md) {
      color: $black;
      font-size: $font-size-lg;
    }
  }

  .items {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
    }
    .item {
      flex-basis: 50%;
      margin-bottom: 16px;
      padding: 0;

      @include media-breakpoint-up(md) {
        padding-right: 20px;
      }

      &:before {
        display: none;
      }

      &:after {
        content: '';
        background: url('/static/icons/path.svg');
        width: 12px;
        height: 12px;
        display: block;
        position: absolute;
        top: 8px;
        right: 8px;

        @include media-breakpoint-up(md) {
          display: none;
        }
      }

      a {
        display: block;
        color: $slate-1200;
        font-size: $font-size-base;
        padding-top: 8px;
        padding-bottom: 8px;
        @include media-breakpoint-up(md) {
          padding: 0;
        }
        &:hover,
        &:focus,
        &:active,
        &:focus-visible {
          text-decoration: underline;
        }

      }
    }
  }
}
