@import '../../../../../styles/basics';

.bb-hero-video {
  &.with-preview-video {
      // Start from 550px height video and slowly increase as screen height grows.
    @include media-breakpoint-up(md) {
      height: 550px;
    }
    @include media-breakpoint-up(lg) {
      @media (min-height: 900px) {
        height: 700px;
      }
      @media (min-height: 1200px) {
        height: 900px;
      }
    }
  }

  :global {
    .video-container {
      position: absolute;
      bottom: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;

      video {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  &:global(.bb-hero-video-emergency) {
    background-color: $black;

    .gradient {
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.79) 81%,
        $black
      );
      @include media-breakpoint-up(md) {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.95) 92%);
      }
    }

    [data-component="tag-text"] {
      margin-bottom: 12px;
      // override desktop Tag styling, use same as mobile display
      @include media-breakpoint-up(md) {
        margin-bottom: 20px;
      }

      * {
        text-shadow: none;
      }
    }

    [data-component^="button-"] {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
}
